import React, { Component } from 'react';
import './App.css';
import Track from "./components/Track";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="container"> 
             
          <Track></Track>

      
        </div>
        
      </div>
    );
  }

}

export default App;
