import React from 'react';
import { useLastFM } from 'use-last-fm';


const CurrentlyPlaying = () => {
    const lastFM =  useLastFM('coldpolaris', 'f4d0005229540c63b661072864d3994d')
    console.log(lastFM);
    return (
        lastFM.status !== 'playing' ? (
            <h1>GABE HASSAN</h1>
        ) :  (
            <>
    
                <h2>
                    {lastFM.song.name.toUpperCase()}
                </h2>
    
                <img alt="" src={lastFM.song.art}></img>    
            </>
        )
    )
};

export default CurrentlyPlaying;